.menuBar {
  box-shadow: 0px 4px 8px rgba(0 0, 0, 0.1);
  background-color: #fff;
  height: 100%;
  padding: 16px 24px 24px;
  position: fixed;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;

  .content {
    display: flex !important;
    flex-direction: column !important;
    gap: 48px;

    .links {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 24px;

      .link {
        display: flex;
        align-items: center;
        color: #1d334d;
        gap: 8px;
        /* border: 2px solid red; */

        .icon {
          font-size: 22px;
        }

        &.activeLink {
          color: rgb(13 148 136);
        }

        &.notified {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -15px;
            width: 10px;
            height: 10px;
            background-color: red;
            border-radius: 50%;
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

.btn {
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  background-color: #1d334d;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn:hover {
  background-color: #17283c;
}
