.imageWrapper {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 8px;
  &.edit {
    background: rgba(190, 190, 190, 0.308);
    border: 2px dashed #1677ff;
    input {
      cursor: pointer;
    }
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .imgInp {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
