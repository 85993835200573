.container {
  .uploadContent {
    width: 100%;
    border: 1px dashed #1677ff;
    border-radius: 8px;
    position: relative;
    padding: 24px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .icon {
      width: 50px;
      height: 50px;
      color: #1677ff;
    }
  }

  img {
    width: 100%;
  }
}
