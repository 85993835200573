.mediaCard {
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.textIndicator {
  color: #a5afbe;
}

.content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}
