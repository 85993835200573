.mainDiv {
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  .form {
    max-width: 400px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .input {
      background-color: #f9fafb;
      border: 1px solid #d1d5db;
      font-size: 14px;
      border-radius: 12px;
      padding: 16px;
      outline: none;
    }
    .btn {
      color: #ffffff;
      background-color: #1d334d;
      border: none;
      &:hover {
        background-color: #192739;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px #a5c5f8;
      }
      border-radius: 12px;
      font-size: 14px;
      padding: 16px;
      text-align: center;
    }
  }
}

::selection {
  color: #ffffff;
  background-color: #31285c;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Outfit", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
}

a {
  color: #31285c;
}

.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
  padding-top: 20px;
}

.wrapper {
  max-width: 500px;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.wrapper img {
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.inputfield {
  width: 100%;
  height: 45px;
  border: none;
  padding: 10px;
  background-color: #eeeeee;
  color: gray;
  outline: none;
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s;
  border-radius: 5px;
  & :global(.ant-input) {
    background-color: #eeeeee;
    color: gray;
  }
}

.error {
  color: #ff4d4f;
  padding: 0 0 12px;
}

.heading {
  color: #3b3663;
  margin-bottom: 20px;
  margin-top: 20px;
}

.heading p {
  margin-top: 5px;
  color: #aaa8bb;
}

.heading i {
  font-size: 30px;
  color: #4d61fc;
}

label {
  color: #aaa8bb;
  font-weight: 400;
}

.btn {
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  background-color: #1d334d;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s;
}

.btn:hover {
  background-color: #17283c;
}

.row {
  min-width: 5px;
  min-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 15px;
}

.custom-checkbox {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 5px;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #31285c;
}

input[type="checkbox"]:checked ~ .custom-checkbox::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  font-weight: 900;
  color: #ffffff;
}

footer {
  text-align: center;
  margin: 20px;
}
