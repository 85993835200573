.card {
  padding: 24px;
  border-radius: 8px;
  min-height: 170px;
  width: 100%;
  .icon {
    font-size: 30px;
    color: #fff;
  }
  .title {
    color: #fff;
  }
  .amountsDiv {
    width: 100%;
    .amount {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.green {
  background-color: rgb(13 148 136);
}
.orange {
  background-color: rgb(251 146 60);
}
.teal {
  background-color: rgb(8 145 178);
}
.blue {
  background-color: rgb(2 132 199);
}
