.mediaCardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  row-gap: 32px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
}
