.card {
  width: 100%;
  border: 1px solid rgb(229 231 235);
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  .icon {
    background-color: rgb(219 234 254);
    border-radius: 50%;
    padding: 12px 14px;
    font-size: 20px;
    color: #1d334d;
  }
  .numberOfOrders {
    font-size: 22px;
    font-weight: bold;
  }
}
