.mediaCard {
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  .textContent {
    height: 100%;
    .textIndicator {
      color: #a5afbe;
    }
  }

  .imgContainer {
    width: 100%;

    .img {
      height: 300px;
      max-width: 350px;
      width: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  .btn {
    max-width: 150px;
  }
}
