.imageWrapper {
  max-width: 500px;
  position: relative;

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    @media (max-width: 1024px) {
      aspect-ratio: 8/4;
    }
    @media (max-width: 744px) {
      aspect-ratio: 7/4;
    }
  }

  .uploader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2231339d;
    z-index: 2;
    p {
      font-size: 14px;
      font-weight: 22px;
      font-weight: 600;
      color: white;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .icon {
      width: 50px;
      height: 50px;
      color: #1677ff;
    }
  }
}
