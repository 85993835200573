.editModal {
    & :global(.ant-modal-footer) {
      display: flex !important;
    }
  }
  
  .prevImg {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding-bottom: 12px;
  }
  