.collapse {
  .collapse__header {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    @media (max-width: 1024px) {
      gap: 10px;
    }

    .collapse__header--title,
    .collapse__header--innerTitle {
      cursor: pointer;
    }

    .collapse__header--title {
      color: #193352;
      font-size: 25px;
      font-weight: bold;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    .collapse__header--innerTitle {
      padding: 5px 0;
      color: #193352;
      font-size: 18px;
      font-weight: 400;

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  .collapse__content {
    color: #193352;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    padding: 8px 0 8px 30px;

    @media (max-width: 1024px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 4px 0 4px 25px;
    }
  }
}
