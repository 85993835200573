.layout {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #f9fbfd;
  position: relative;
  .header {
    padding: 24px 0;
    margin-bottom: 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    .headerContent {
      max-width: 1280px;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .icon {
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .outlet {
    max-width: 1280px;
    margin: auto;
    padding: 24px;
    width: 100%;
    min-height: 100vh;
    margin-bottom: 24px;
  }
  .menuOpen {
    padding: 0 24px;
    @media (max-width: 1024px) {
      padding: 0 48px;
    }
    @media (max-width: 550px) {
      padding: 0 24px;
    }
  }
  .menuClosed {
    padding: 0 48px;
    @media (max-width: 550px) {
      padding: 0 24px;
    }
  }
}

.open {
  grid-template-columns: 250px auto;
  @media (max-width: 1024px) {
    grid-template-columns: auto;
  }
}
.closed {
  grid-template-columns: auto;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
