.contact {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.icon {
  font-size: 20px;
  text-align: center;
  color: #939aa7;
  cursor: pointer;
  &:hover {
    color: #17283c;
  }
}
