.editModal {
  .body {
    padding: 16px 0;
    .content {
      > p {
        padding-bottom: 4px;
        color: #a5afbe;
      }
    }
  }
  & :global(.ant-modal-footer) {
    display: flex !important;
  }
}

.prevImg {
  width: 100%;
  height: 150px;
  object-fit: contain;
  padding-bottom: 12px;
}
