.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1194px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.amountsCard {
  height: 170px !important;
}
.ordersCard {
  height: 84px !important;
}
